/* Contact Section */
#contactPage {
    padding: 4rem 2rem;
    background: #1c1c1c;
    color: #c0a7f2;
    text-align: center;
  }
  
  #contact {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contactPageTitle {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #c0a7f2; 
    font-weight: 400;
  }
  
  .contactForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .contactForm input,
  .contactForm textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 12px;
    font-size: 1rem;
    background-color: #444;
    color: #fff;
  }
  
  .contactForm input::placeholder,
  .contactForm textarea::placeholder {
    color: #bbb;
  }
  
  .contactForm input:focus,
  .contactForm textarea:focus {
    outline: none;
    border-color: #c0a7f2;
  }
  
  .submitBtn {
    padding: 0.5rem;
    background-color: #c0a7f2;
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitBtn:hover {
    background-color: #c0a7f2;
  }
  
  @media (max-width: 768px) {
    .contactPageTitle {
      font-size: 2.5rem;
    }
  
    .contactForm input,
    .contactForm textarea {
      font-size: 1rem;
    }
  
    .submitBtn {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .contactPageTitle {
      font-size: 2rem;
    }
  
    .contactForm input,
    .contactForm textarea {
      font-size: 0.9rem;
    }
  
    .submitBtn {
      font-size: 0.9rem;
    }
  }
  