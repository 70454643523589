/* Skills Section */
#skills {
    padding: 2rem 2rem;
    background-color: #1c1c1c;
    text-align: center;
  }
  
  .skillTitle {
    display: block;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #c0a7f2;
    font-weight: 400;
  }
  
  .skillDescription {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: white;
    max-width: 800px;
    margin: 0 auto;
    animation: fadeIn 2s ease-in-out;
  }
  
  .skillbars {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  
  .skillBar {
    background: #666;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: left;
    display: flex;
    max-width: 500px;
    width: 100%;
    margin: 1rem;
  }
  
  .skillBarImg {
    width: 110px;
    height: 110px;
    object-fit:contain;
    padding: 1rem;
    margin:1rem;
  }
  
  .skillBartext {
    padding: 1rem;
    flex-grow: 1;
  }
  
  .skillBartext h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: white;
  }
  
  .skillBartext p {
    font-size: 1rem;
    color: white;
  }
  
  @media (max-width: 768px) {
    .skillbars {
      flex-direction: column;
      align-items: center;
    }
  
    .skillBar {
      flex-direction: column;
      text-align: center;
    }
  
    .skillBarImg {
      width: 100%;
      height: auto;
    }
  
    .skillBartext {
      padding: 1rem;
    }
  }
  