#hero {
    position: relative;
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    background:#1c1c1c ;
    color: #f4e6e5;
  }
  
  .bg {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;
  }
  
  .introContent {
    height: 100vh;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    animation: fadeIn 2s ease-in-out;
  }
  
  .hello {
    font-size: 2rem;
    font-weight: 300;
  }
  
  .introText {
    font-size: 3rem;
    font-weight: 700;
    margin: 1rem 0;
  }
  
  .introName {
    color: #c0a7f2;
  }
  
  .introPara {
    font-size: 1rem;
    font-weight: 300;
    max-width: 600px;
  }
  
  .ctaButton {
    margin-top: 2rem;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    background-color: #b491c8;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .ctaButton:hover {
    background-color: #a777e3;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @media screen and (max-width: 840px) {
    .introContent {
      font-size: 5vw;
    }
  
    .hello {
      font-size: 4vw;
    }
  
    .introText {
      font-size: 6vw;
    }
  }
  
  @media screen and (max-width: 480px) {
    .introContent {
      font-size: 8vw;
    }
  
    .hello {
      font-size: 6vw;
    }
  
    .introText {
      font-size: 8vw;
    }
  }
  