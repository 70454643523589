/* Navbar styles */
.navbar {
    height: 5rem;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #202020;
    transition: all 0.3s ease;
}


.desktopMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease, border-bottom 0.3s ease;
}

.desktopMenuListItem:hover {
    color: #c0a7f2;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #c0a7f2;
    transform: translateY(-3px);
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger div {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px;
    transition: all 0.3s ease;
}

.mobileMenu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    background-color: #202020;
    padding: 1rem 2rem;
    z-index: 2;
}

.mobileMenu.active {
    display: flex;
}

.mobileMenuListItem {
    margin: 1rem 0;
    width: 100%;
    text-align: left;
    padding: 0.5rem 0;
    border-bottom: 1px solid #333;
    cursor: pointer;
    transition: color 0.3s ease;
}

.mobileMenuListItem:hover {
    color: #c0a7f2;
}

/* Responsive styles */
@media (max-width: 768px) {
    .desktopMenu {
        display: none;
    }

    .hamburger {
        display: flex;
    }
}
