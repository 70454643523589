.about {
  padding: 4rem 2rem;
  background: #1c1c1c;
  color: #fff;
  text-align: center;
}

.aboutContent {
  max-width: 800px;
  margin: 0 auto;
}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #c0a7f2;
  font-weight: 300;
}

.aboutDetails {
  margin-bottom: 2rem;
}

.about p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
}

.socialLinks {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.socialLinks a {
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.socialLinks a:hover {
  color: #c0a7f2;
}

/* Responsive styles */
@media (max-width: 768px) {
  .about p {
    font-size: 1rem;
  }
}
