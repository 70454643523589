/* Projects Section */
#projects {
    padding: 4rem 2rem;
    background: #1c1c1c;
    color: #fff;
    text-align: center;
  }
  
  .projects {
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #292929, #3a3a3a);
    color: #fff;
    text-align: center;
  }
  
  .worksTitle {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #b491c8; 
    font-weight: 400;
  }
  
  .worksDescription {
    font-size: 1.5rem;
    font-weight: 300;
    max-width: 800px;
    margin: 0 auto 2rem auto;
    color: #ddd;
  }
  
  .projectImgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  
  .projectImg {
    width: 100%;
    max-width: 30rem;
    height: 20rem;
    object-fit: cover;
    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .projectImg:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .worksTitle {
      font-size: 2.5rem;
    }
  
    .worksDescription {
      font-size: 1rem;
    }
  
    .projectImg {
      height: 15rem;
    }
  }
  
  @media (max-width: 480px) {
    .worksTitle {
      font-size: 2rem;
    }
  
    .worksDescription {
      font-size: 0.9rem;
    }
  
    .projectImg {
      height: 12rem;
    }
  }
  